var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-page-layout',{attrs:{"title":"Vouchers"}},[_c('v-card',{staticClass:"mt-3",attrs:{"flat":""}},[_c('v-card-title',{staticClass:"mb-6"},[_c('v-text-field',{staticClass:"pt-0 mt-0",attrs:{"width":"200","hide-details":"","single-line":"","label":"Busca","append-icon":"search"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer')],1),_c('data-table-pagination',{attrs:{"search":_vm.search,"headers":_vm.headers,"query":_vm.query,"queryName":"vouchers","account-id":_vm.accountId,"query-params":_vm.queryParams},scopedSlots:_vm._u([{key:"item.document_no",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.document_no)+" ")]}},{key:"item.active_voucher",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"color":item.active_voucher.activated_at ? 'success' : 'error',"left":"","x-small":""}},[_vm._v("mdi-brightness-1")]),_vm._v(" "+_vm._s(item.active_voucher.title ? 'Ativo' : 'Sem voucher ativo')+" ")]}},{key:"item.activated_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.active_voucher.activated_at) || '-')+" ")]}},{key:"item.title",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.active_voucher.title || '-')+" ")]}},{key:"item.initial_date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.active_voucher.initial_date) || '-')+" ")]}},{key:"item.final_date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.active_voucher.final_date) || '-')+" ")]}},{key:"item.details",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"bottom":"","transition":"slide-x-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"outlined":"","small":"","color":"primary","dark":""}},'v-btn',attrs,false),on),[_vm._v("Detalhes"),_c('v-icon',{staticClass:"ml-1",attrs:{"small":""}},[_vm._v(_vm._s(!item.active_voucher.activated_at && !item.voucher_history ? 'mdi-cancel' : 'mdi-menu-down'))])],1)]}}],null,true)},[_c('v-list',{staticClass:"ma-0 pa-0"},[(item.active_voucher.activated_at)?_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.openActiveVoucher(item)}}},[_c('v-list-item-title',[_c('v-icon',{staticClass:"mb-1 mr-2"},[_vm._v("mdi-ticket")]),_vm._v(" Voucher Ativo")],1)],1):_vm._e(),(item.voucher_history)?_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.openHistoryVouchers(item)}}},[_c('v-list-item-title',[_c('v-icon',{staticClass:"mb-1 mr-2"},[_vm._v("mdi-ticket-outline")]),_vm._v(" Histórico")],1)],1):_vm._e()],1)],1)]}}])})],1),(_vm.activeVoucher)?_c('active-voucher',{attrs:{"user":_vm.userDocument,"dialog":_vm.activeVoucherDialog,"voucher":_vm.activeVoucher},on:{"close":_vm.close}}):_vm._e(),(_vm.voucherId)?_c('voucher-history',{attrs:{"user":_vm.userDocument,"dialog":_vm.historyVoucherDialog,"voucher-id":_vm.voucherId,"account-id":_vm.accountId},on:{"close":_vm.close}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }