<template>
  <base-page-layout title="Vouchers">
    <v-card flat class="mt-3">
      <v-card-title class="mb-6">
        <v-text-field v-model="search" class="pt-0 mt-0" width="200" hide-details single-line="" label="Busca" append-icon="search" />
        <v-spacer />
      </v-card-title>
      <data-table-pagination :search="search" :headers="headers" :query="query" queryName="vouchers" :account-id="accountId" :query-params="queryParams">
        <!-- eslint-disable-next-line -->
        <template v-slot:item.document_no="{ item }">
          {{ item.document_no }}
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.active_voucher="{ item }">
          <v-icon :color="item.active_voucher.activated_at ? 'success' : 'error'" left x-small>mdi-brightness-1</v-icon>
          {{ item.active_voucher.title ? 'Ativo' : 'Sem voucher ativo' }}
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.activated_at="{ item }">
          {{ formatDate(item.active_voucher.activated_at) || '-' }}
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.title="{ item }">
          {{ item.active_voucher.title || '-' }}
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.initial_date="{ item }">
          {{ formatDate(item.active_voucher.initial_date) || '-' }}
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.final_date="{ item }">
          {{ formatDate(item.active_voucher.final_date) || '-' }}
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.details="{ item }">
          <v-menu bottom transition="slide-x-transition">
            <template v-slot:activator="{ on, attrs }">
              <v-btn outlined small color="primary" dark v-bind="attrs" v-on="on"
                >Detalhes<v-icon small class="ml-1">{{ !item.active_voucher.activated_at && !item.voucher_history ? 'mdi-cancel' : 'mdi-menu-down' }}</v-icon>
              </v-btn>
            </template>
            <v-list class="ma-0 pa-0">
              <v-list-item v-if="item.active_voucher.activated_at" link @click="openActiveVoucher(item)">
                <v-list-item-title><v-icon class="mb-1 mr-2">mdi-ticket</v-icon> Voucher Ativo</v-list-item-title>
              </v-list-item>
              <v-list-item v-if="item.voucher_history" link @click="openHistoryVouchers(item)">
                <v-list-item-title><v-icon class="mb-1 mr-2">mdi-ticket-outline</v-icon> Histórico</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </data-table-pagination>
    </v-card>

    <!-- dialogs -->
    <active-voucher v-if="activeVoucher" :user="userDocument" :dialog="activeVoucherDialog" :voucher="activeVoucher" @close="close" />
    <voucher-history v-if="voucherId" :user="userDocument" :dialog="historyVoucherDialog" :voucher-id="voucherId" :account-id="accountId" @close="close" />
  </base-page-layout>
</template>

<script>
import { QUERY_VOUCHERS } from '@/modules/accounts/graphql'

export default {
  components: {
    BasePageLayout: () => import('@/components/base/BasePageLayout.vue'),
    DataTablePagination: () => import('@/components/tables/DataTablePagination.vue'),
    ActiveVoucher: () => import('@/modules/accounts/components/vouchers/ActiveVoucher'),
    VoucherHistory: () => import('@/modules/accounts/components/vouchers/VoucherHistory')
  },
  data: () => ({
    accountId: '',
    voucherId: '',
    search: '',
    activeVoucherDialog: false,
    historyVoucherDialog: false,
    activeVoucher: null,
    userDocument: ''
  }),
  computed: {
    query() {
      return QUERY_VOUCHERS
    },
    queryParams() {
      return { account_id: this.accountId }
    },
    headers() {
      return [
        {
          text: 'Nº Documento',
          value: 'document_no',
          width: '12%'
        },
        {
          text: 'Título',
          value: 'title',
          width: '14%'
        },
        {
          text: 'Status',
          value: 'active_voucher',
          width: '15%'
        },
        {
          text: 'Ativado em',
          value: 'activated_at',
          width: '15%',
          sortable: false
        },
        {
          text: 'Data Inicial',
          value: 'initial_date',
          width: '15%',
          sortable: false
        },
        {
          text: 'Data Final',
          value: 'final_date',
          width: '15%',
          sortable: false
        },
        {
          text: '',
          value: 'details',
          width: '12%',
          sortable: false
        }
      ]
    }
  },
  mounted() {
    this.accountId = this.$route.params.id
  },
  methods: {
    formatDate(date) {
      if (date) return this.$moment(date).format('DD/MM/YYYY, HH:mm:ss')
      return '-'
    },
    openActiveVoucher(voucher) {
      this.userDocument = voucher.document_no
      this.activeVoucher = voucher.active_voucher
      this.activeVoucherDialog = true
    },
    openHistoryVouchers(voucher) {
      this.userDocument = voucher.document_no
      this.voucherId = voucher._id
      this.historyVoucherDialog = true
    },
    close() {
      this.activeVoucherDialog = false
      this.historyVoucherDialog = false
      this.activeVoucher = null
      this.voucherId = null
      this.userDocument = ''
    }
  }
}
</script>
